import React, { useState, useEffect } from 'react';
import { Input, Button, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import Tabs from './Tabs/Tabs';
import OutTab from './OutTab/OutTab';
import CourseOfCard from '../../components/CourseOfCard/CourseOfCard';
import axios from 'axios';
import { apiUrl } from '../../api/ApiService';

import IconSearch from '../../assets/img/icons/iconSearch';
import iconCourseDesign from '../.././assets/img/DesignUI.svg';
import iconCourseCode from '../.././assets/img/IconCode.svg';
import MyLoader from '../../components/CourseOfCard/Loader';

import { changeProgress } from '../../redux/courses/tabCard';
import { fetchGroupCourses } from '../../redux/courses/courses';

import './Courses.scss';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isActiveTab, setIsActiveTab] = useState(null);

  const token = localStorage.getItem('token');

  const filteredData = courses.filter((course) =>
    course.full_name?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const location = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const groupCourses = useSelector((state) => state.courses.groupCourses);
  const wordInQuery = new RegExp(`\\b${'courses'}\\b`, 'i').test(location.pathname);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  async function fetchAllCourses() {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v1/courses`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      });
      setCourses(data.map((course) => ({ ...course, isVisible: true })));
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    dispatch(fetchGroupCourses());
  }, [dispatch]);
  useEffect(() => {
    async function fetchCourses() {
      try {
        let url;
        if (location.pathname === '/') {
          url = `${apiUrl}/api/v1/courses/${selectedCategory}`;
        } else {
          if (!selectedCategory) return;
          url = `${apiUrl}/api/v1/groupModules/${selectedCategory}`;
        }

        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ` + token,
          },
        });

        const moduleCourse = data.map((course) => ({ ...course, isVisible: true }));
        setCourses(moduleCourse);
        dispatch(changeProgress(moduleCourse));
      } catch (error) {
        console.error('Error:', error);
      }
    }

    async function fetchCategories() {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v1/categories`, {
          headers: {
            Authorization: `Bearer ` + token,
          },
        });
        setCategories(data);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    if (location.pathname === '/') {
      fetchCategories();
    }

    fetchCourses();
  }, [dispatch, selectedCategory]);
  useEffect(() => {
    fetchAllCourses();
  }, [location]);
  const handleTabClick = () => {
    setSelectedCategory(null);
    setIsActiveTab(null);
    fetchAllCourses();
  };

  return (
    <div className="courses">
      <Tabs>
        {!wordInQuery && (
          <p
            className={selectedCategory === null ? 'activeCategory' : 'noActiveCategory'}
            onClick={handleTabClick}>
            Все
          </p>
        )}

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}>
          {location.pathname === '/'
            ? categories.map((category) => (
                <div
                  className={category.id === isActiveTab ? 'active' : 'tab'}
                  key={category.id}
                  onClick={() => {
                    setSelectedCategory(category.id);
                    setIsActiveTab(category.id);
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}>
                      <p className={category.id === isActiveTab ? 'activeCategory' : ''}>
                        {category.title.charAt(0).toUpperCase() + category.title.slice(1)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : groupCourses?.map((item) => {
                const isActive = location.pathname === `/courses/${item.id}`;
                const tabClass = isActive ? `tab tab-${item.id} ` : `tab tab-${item.id}`;
                const isIdMatching = item.id === id;

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setSelectedCategory(item.id);
                      setIsActiveTab(item.id);
                    }}
                    key={item.id}>
                    <NavLink
                      style={{
                        textDecoration: 'none',
                        color: isIdMatching ? 'black' : 'inherit',
                      }}
                      to={`/courses/${item.id}`}>
                      <p className={isActive ? 'activeCategory' : `${tabClass}`}>
                        {item.short_name.slice(0, 18)}
                      </p>
                    </NavLink>
                  </div>
                );
              })}
        </div>

        {location.pathname === '/' ? (
          <div className="button-group-admins ">
            <Input
              style={{ background: '#f0f0f0', border: '0px', width: '280px' }}
              placeholder="Быстрый поиск"
              value={searchTerm}
              onChange={handleSearchChange}
              prefix={<IconSearch />}></Input>
            <Link to="/add-course">
              <Space wrap>
                <Button type="primary">
                  <svg
                    className="img-plus"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M12 5V19"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>Добавить курс</p>
                </Button>
              </Space>
            </Link>
          </div>
        ) : (
          ''
        )}
      </Tabs>
      {location.pathname !== '/' ? (
        <OutTab urlId={id} />
      ) : filteredData.length > 0 ? (
        <CourseOfCard
          courses={courses}
          setCourses={setCourses}
          selectedCategory={selectedCategory}
          filteredData={filteredData}
        />
      ) : (
        [...new Array(8)].map((_, i) => <MyLoader key={i} />)
      )}
    </div>
  );
};

export default Courses;
